@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Poppins');
@layer base {


html {
  /* font-family: 'Poppins', sans-serif; */
}

html {
    scroll-behavior: smooth;
}

/* header elements */
.header-bar {
  background-color: #EDFAFF;
  display: flex;
  justify: between;
  align-items: center;
  padding-top: 0px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header-right-items {
  display:flex; 
  flex-direction: row;
  justify: right;
  color: #054075;
  padding-right: 10px;
}

.header-menu-button {
  display: None;
}

.header-options {
  font-size: 1em;
  //font-family: Poppins;
  color: #054D8E;
  padding-right: 60px;
  margin-top: 5px;
}

.header-options:hover {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 5px;
}

.header-get-started-btn {
  background: #054D8E;
  border: 1px solid #ACA9A9;
  border-radius: 8px;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 50px; 
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.header-get-started-btn:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 10px 20px;
  transform: translate3d(0px, -2px, 0px);
}

.header-get-started {
  font-size: 20px;
  //font-family: Poppins;
  font-weight: 600;
  /* padding-top: 10px; */
}

#hyperlink {
  color: blue;
  text-decoration: underline;
}

@media (min-width: 1025px) {

  .header-img-logo {
    /* max-width:70px; */
    height:55px;
  }

  .header-logo-text {
    //font-family: 'Poppins';
    color: #054D8E;
    font-size: 1.3em;
    font-weight: 800;
  }

.first_div {
  background-color: #EDFAFF;
  height: 550px;
  /* position: relative; */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: start; /* Center vertically */
}

.first_div_base_hook {
  margin-top: 60px;
  /* //font-family: 'Avenir', serif; */
  color: #054D8E;
  font-style: normal;
  font-weight: 600;
  font-size: 2.8em;
  line-height: 1.2em;
  text-align: center;
}

.first_div_typeout {
  color: #CE1515;
  text-shadow: 0 0 1px black;
  /* -webkit-text-stroke: 1.5px black; */
}

.typing_mobile {
  display: None;
}

.first_div_subhook {
  /* //font-family: 'Avenir', serif; */
  color: #054D8E;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 1em;
  margin-top: 9%;
}

#first_div_lumos {
  background-color: rgba(240, 207, 48, 0.4);
}

.firstdiv-get-started-btn {
  background: #054D8E;
  border: 1px solid #ACA9A9;
  border-radius: 8px;
  height: 80px;
  width: 38%;
  display: block;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-left: 30%;
  color: white;
  font-size: 28px;
  /* //font-family: Avenir; */
  font-weight: 600;
  margin-top: 13%; 
}

.firstdiv-get-started-btn:hover {
  background: rgba(5,19,142,1);
}

.second_div {
  background-color: white;
  /* height: 1800px; */
  
  /* align-items: start; Center vertically */
}

.second_div_mobile {
  display: None;
}

.second_div_main_title {
  /* //font-family: 'Avenir', serif; */
  color: #054D8E;
  font-size: 2em;
  font-weight: 400;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  
}

.second_div_reason1 {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  height: 410px;
}

.second_div_reason1_img {
  width: 400px;
  height: 280px;
  margin-right: 6%;
  margin-top: 70px;
}

.second_div_reason1_text {
  /* //font-family: 'Avenir', serif; */
  color: #054D8E;
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 150px;
}

.second_div_reason1_subtext {
  /* //font-family: 'Avenir', serif; */
  color: #054D8E;
  font-size: 0.7em;
  font-weight: 500;
  text-align: left;
  line-height: 1.3em;
  margin-top: 5px;
}

.second_div_reason2 {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  height: 370px;
}

.second_div_reason2_img {
  height: 280px;
  margin-left: 5%;
}

.second_div_reason2_text {
  /* //font-family: 'Avenir', serif; */
  color: #054D8E;
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 100px;
  margin-left: 3%;
  line-height: 1.1em;
}

.second_div_reason2_subtext {
  /* //font-family: 'Avenir', serif; */
  color: #054D8E;
  font-size: 0.7em;
  font-weight: 500;
  text-align: left;
  margin-top: 10px;
}

.second_div_reason3 {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  height: 280px;
}

.second_div_reason3_img {
  height: 180px;
  margin-right: 4%;
}

.second_div_reason3_text {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 15px;
  line-height: 1.1em;
}

.second_div_reason3_subtext {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 0.7em;
  font-weight: 500;
  text-align: left;
  line-height: 1.3em;
  margin-top: 5px;
  margin-right: 1%;
  max-width: 500px;
}

.second_div_reason4 {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  height: 380px;
}

.second_div_reason4_img {
  /* width: 510px; */
  height: 360px;
  /* margin-left: 2%; */
  /* margin-right: 1%; */
}

.second_div_reason4_text {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 100px;
  /* margin-right: 1%; */
  line-height: 1.1em;
}

.second_div_reason4_subtext {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 0.7em;
  font-weight: 500;
  text-align: left;
  line-height: 1.1em;
  margin-top: 10px;
  width: 500px;
  text-wrap: balance;
}

.second_div_reason6 {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  height: 360px;
  margin-top: 40px;
}

.second_div_reason6_img {
  width: 460px;
  margin-right: 4%;
}

.second_div_reason6_text {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 80px;
  line-height: 1.2em;
}

.second_div_reason6_subtext {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 0.7em;
  font-weight: 500;
  text-align: left;
  line-height: 1.1em;
  margin-top: 5px;
  width: 500px;
  text-wrap: balance;
}

.third_div {
  background-color: #EDFAFF;
  height: 500px;
}

.reviews_mobile {
  display: None;
}

.third_div_main_title {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 30px;
  font-weight: 400;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.third_div_review_cards {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.third_div_review_card {
  background-color: white;
  border-radius: 10px;
  font-size: 1em;
  padding: 2px;
  border: 1px solid black;
  width: 100%;
  height: 80%;
  margin-right: 1%;
}

.third_div_review_card_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.third_div_review_name {
  font-size: 18px;
  color: #054D8E;  
  font-weight: 600;  
}

.third_div_review_stars {
  width: 120px;
}

.third_div_footer {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 18px;
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}

.carousel-container {
  overflow: hidden;
  position: relative;
  height: 350px;
  border-color: #4b5563;
  width: 100%;
}

.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  justify-content: space-between;
  
  margin-top: 50px;
  width: 400%;
  height: 90%;
  animation: slide 60s linear infinite;
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.fourth_div_main_title {
  //font-family: 'Avenir', serif;
  color: #054D8E;
  font-size: 30px;
  font-weight: 600;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.fourth_div_faqs {
  display: flex; 
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.faq_arrow {
  width: 0.75rem;
  height: 0.75rem;
}

.faq_width {
  width: 800px;
}

.faq_question {
  font-size: large;
}

.faq_answer {
  font-size: large;
}

.faq_hover:hover {
  background: rgb(209 213 219);
  color: #054075;
}

.footer_space {
  margin-top: 4vh;
}

.about_page_desktop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  /* background: #EDFAFF; */
}

.about_page_mobile {
  display: None;
}

.about_page_text{
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 600;
  text-wrap: balance;
  width: 40vw;
  max-width: 700px;
  margin-right: 50px;
  color: #054075;

}

.about_page_title{
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 600;
}

.about_page_details{
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3em;
}

.about_page_image{
  margin-left: 50px;
}

}


@media (max-width: 1024px) {

  .header-right-items {
    display: None;  
  }

  .header-menu-button {
    display: flex;
    font-size: 30px;
    margin-right: 20px;
  }

  .header-img-logo-mobile {
    width:175px;
  }

  .menu {
    font-size: 18px;
    color: #054075;
    font-weight: medium;
    list-style-type: none;
    display: none; /* Hide the menu items by default on smaller screens */
    flex-direction: column; /* Stack menu items vertically */
    position: absolute;
    top: 60px;
    left: 0;
    border: solid 1px black;
    /* border-top: 0px; */
    background-color: #EDFAFF;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    z-index: 100;
  }

  .menu.active {
    display: flex; /* Display the menu items when the menu is active */
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
  }

  .header-logo-text {
    //font-family: 'Avenir';
    color: #054D8E;
    font-size: 1.1em;
    font-weight: 800;
  }

  .first_div {
    background-color: #EDFAFF;
    /* height: 40vh; */
    padding-bottom: 40px;
    height: 100%;
    display: flex;
    justify-content: left; /* Center horizontally */
    align-items: start; /* Center vertically */
  }
  
  .first_div_base_hook {
    margin-top: 8vw;
    //font-family: 'Avenir', serif;
    color: #054D8E;
    font-style: normal;
    font-weight: 600;
    font-size: 2.3em;
    line-height: 1.1em;
    text-align: left;
    margin-left: 5vw;
    margin-right: 5px;
  }
  
  .first_div_typeout {
    //font-family: 'Avenir', serif;
    color: #CE1515;
    text-shadow: 0 0 1px black;
  }

  .typing_desktop {
    display: None;
  }
  
  .first_div_subhook {
    //font-family: 'Avenir', serif;
    color: #054D8E;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 1.2em;
    margin-top: 10vh;
    margin-right: 6px;
  }
  
  #first_div_lumos {
    background-color: rgba(240, 207, 48, 0.4);
  }
  
  .firstdiv-get-started-btn {
    background: #054D8E;
    border: 1px solid #ACA9A9;
    border-radius: 8px;
    height: 8vh;
    width: 55vw;
    max-width: 400px;
    display: block;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    color: white;
    font-size: 0.6em;
    //font-family: Avenir;
    font-weight: 600;
    text-align: center;
    /* padding-left: 5vw; */
    margin-top: 11vh;
  }
  
  .firstdiv-get-started-btn:hover {
    background: rgba(5,19,142,1);
  }

  .second_div {
    display: None;
  }

  .second_div_mobile {
    background-color: white;
    padding-bottom: 20px;
  }
  
  .second_div_main_title {
    //font-family: 'Avenir', serif;
    color: #054D8E;
    font-size: 1.75em;
    font-weight: 400;
    margin-top: 4vh;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    line-height: 1.1em;
  }
  
  .second_div_reason {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .second_div_reason_img {
    width: 80%;
    /* height: 40px; */
    /* margin-right: 80px; */
    margin-top: 3vh;
  }

  .second_div_reason_img1 {
    width: 65%;
    /* height: 40px; */
    /* margin-right: 80px; */
    margin-top: 4vh;
  }

  .second_div_reason_img2 {
    width: 60%;
    /* height: 40px; */
    /* margin-right: 80px; */
    margin-top: 3vh;
  }

  .second_div_reason_img5 {
    width: 70%;
    /* height: 40px; */
    /* margin-right: 80px; */
    margin-top: 3vh;
  }

  .second_div_reason_img6 {
    width: 70%;
    /* height: 40px; */
    /* margin-right: 80px; */
    margin-top: 3vh;
  }
  
  .second_div_reason_text {
    //font-family: 'Avenir', serif;
    color: #054D8E;
    font-size: 1.4em;
    font-weight: 500;
    text-align: center;
    margin-top: 3vh;
    line-height: 1.05em;
    text-wrap: balance;
    width: 95%;
  }
  
  .second_div_reason_subtext {
    //font-family: 'Avenir', serif;
    color: #054D8E;
    font-size: 0.8em;
    font-weight: 300;
    text-align: center;
    line-height: 1em;
    margin-top: 10px;
    margin-bottom: 15px;
    /* font-style: italic; */
  }

  .third_div {
    background-color: #EDFAFF;
    /* height: 60%; */
    padding-bottom: 20px;
  }

  .reviews_desktop {
    display:None;
  }

  .third_div_main_title {
    //font-family: 'Avenir', serif;
    color: #054D8E;
    font-size: 1.8em;
    font-weight: 400;
    padding-top: 4vh;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.1em;
    width: 100%;
  }

  .third_div_review_card {
    width: 90%;
    height: 90%;
    background-color: white;
    border-radius: 10px;
    font-size: 1em;
    padding: 2px;
    border: 1px solid black;
    margin-left: 5%;
    margin-top: 40px;
  }
  
  .third_div_review_card_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .third_div_review_name {
    font-size: 1em;
    color: #054D8E;  
    font-weight: 600;  
  }
  
  .third_div_review_stars {
    width: 22.5%;
    min-width: 100px;
    max-width: 120px;
  }

  .third_div_review_content {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

  }

  .third_div_footer {
    //font-family: 'Avenir', serif;
    color: #054D8E;
    font-size: 1em;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    /* margin-bottom: 5vh; */
  }

  .fourth_div {
    /* height: 60vh; */
  }

  .fourth_div_main_title {
    //font-family: 'Avenir', serif;
    color: #054D8E;
    font-size: 1.3em;
    font-weight: 600;
    padding-top: 4vh;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .fourth_div_faqs {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    
  }
  
  .faq_width {
    width: 95vw;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .faq_question {
    font-size: small;
  }
  
  .faq_answer {
    font-size: small;
  }

  .faq_arrow {
    width: 0.5rem;
    height: 0.5rem;
  }

  .footer_space {
    margin-top: 2vh;
  }

  .about_page_desktop {
    display:None;
    /* background: #EDFAFF; */
  }
  
  .about_page_mobile {
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .about_page_text{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
    width: 90%;
    color: #054075;
  
  }
  
  .about_page_title{
    font-size: 30px;
    font-weight: 600;
  }
  
  .about_page_details{
    font-size: 20px;
    font-weight: 300;
    line-height: 1.3em;
  }
  
  .about_page_image{
    margin-top: 50px;
  }

}

}
